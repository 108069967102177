import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Image from '../../atoms/Image';
// import classNames from 'classnames';

const apiUrl = process.env.API_ROOT_PATH + process.env.ARTICLE_INFO_API_PATH;

const SkeletonCard = () => (
  <div
    className="flex flex-col h-full gap-3 text-black md:flex-row lg:flex-col animate-pulse"
    aria-label="Article loading"
  >
    <div className="flex-1">
      <div className="bg-gray-200 pt-1/2" aria-hidden="true" />
    </div>

    <div className="flex-1" aria-hidden="true">
      <h4 className="mb-2 text-transparent bg-gray-200">
        Lorem ipsum dolor sit amet.
      </h4>
      <p className="text-transparent bg-gray-200">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi impedit
        accusamus ea enim aliquid nostrum sit voluptatibus labore, est id
        dignissimos distinctio ad earum fuga sapiente provident iusto nisi
        libero!
      </p>
    </div>
  </div>
);

const ArticleCard = ({ article, lazyloadImage }) => {
  const [articleInfo, setArticleInfo] = useState(article);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [error, setError] = useState(false);

  const handleErrorImage = () => {
    const img = (
      <Image
        width="700"
        height="350"
        src="https://imgix.cosmicjs.com/bae32d30-5e4b-11ea-aae7-630f03f2aad6-ImageNotFound.png?auto=format"
        alt={article.title}
        lazyload={lazyloadImage}
      />
    );

    setImage(img);
  };

  const getImage = async url => {
    try {
      const newUrl = new URL(url);
      if (
        newUrl &&
        (newUrl.host === 'imgix.cosmicjs.com' ||
          newUrl.host === 'cosmic-s3.imgix.net')
      ) {
        return (
          <Image
            width="700"
            height="350"
            src={`${url}?auto=format&fit=crop`}
            alt={article.title}
            lazyload={lazyloadImage}
          />
        );
      }
      return (
        <img
          style={{ objectFit: 'cover' }}
          width="700"
          height="350"
          src={url}
          alt={article.title}
          onError={handleErrorImage}
          loading={lazyloadImage ? 'lazy' : 'eager'}
        />
      );
    } catch (err) {
      return (
        <Image
          src="https://imgix.cosmicjs.com/bae32d30-5e4b-11ea-aae7-630f03f2aad6-ImageNotFound.png?auto=format"
          alt={article.title}
          lazyload={lazyloadImage}
        />
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const articleData = await axios.get(
          `${apiUrl}?url=${encodeURIComponent(article)}`,
        );
        setArticleInfo(articleData.data);
        const img = await getImage(
          articleData.data.feature_image ||
            articleData.data.image_url ||
            articleData.data.image,
        );
        setImage(img);
        // Comment out below to test skeleton cards
        setLoading(false);
      } catch {
        setError(true);
      }
    })();
  }, []);

  return error ? null : (
    <>
      {loading ? (
        <SkeletonCard />
      ) : (
        <a
          className="flex flex-col h-full gap-3 text-black md:flex-row lg:flex-col"
          href={articleInfo.source_url}
        >
          <figure className="flex-1">{image !== null && image}</figure>

          <div className="flex-1">
            <h4 className="mb-2">{articleInfo.title}</h4>
            <p>
              {articleInfo.description
                ? articleInfo.description.replace(/<[^>]*>?/gm, '')
                : ''}
            </p>
          </div>
        </a>
      )}
    </>
  );
};

ArticleCard.defaultProps = {
  lazyloadImage: true,
};

ArticleCard.propTypes = {
  article: PropTypes.string.isRequired,
  lazyloadImage: PropTypes.bool,
};

export default ArticleCard;
