import React from 'react';
import PropTypes from 'prop-types';
import ZipForm from '../../molecules/ZipForm';

const ZipCtaSection = ({ heading, subheading }) => (
  <section className="relative overflow-hidden bg-primary-700">
    <div className="container relative flex flex-col py-10 text-white md:items-center">
      <h3 className="mb-2">{heading}</h3>
      <p className="mb-4 text-lg">{subheading}</p>

      <ZipForm />
    </div>
  </section>
);

ZipCtaSection.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
};

export default ZipCtaSection;
