import React from 'react';
import PropTypes from 'prop-types';
import ArticleCard from '../../molecules/ArticleCard';

const HomeArticles = ({ links, lazyloadImages }) => (
  <div className="container pt-12 pb-16">
    <h2 className="mb-6">Popular Articles</h2>

    <ul className="grid gap-4 lg:grid-cols-3">
      {links.map(article => (
        <li key={article}>
          <ArticleCard article={article} lazyloadImage={lazyloadImages} />
        </li>
      ))}
    </ul>
  </div>
);

HomeArticles.defaultProps = {
  lazyloadImages: true,
};

HomeArticles.propTypes = {
  lazyloadImages: PropTypes.bool,
  links: PropTypes.array.isRequired,
};

export default HomeArticles;
