import React from 'react';
import PropTypes from 'prop-types';
import ZipForm from '../../molecules/ZipForm';
import Button from '../../atoms/Button';

/**
 * Hero Section
 */
const HeroSection = ({
  ctaLabel,
  ctaOnClick,
  heading,
  subheading,
  backgroundImage,
}) => (
  <section
    className="relative overflow-hidden bg-top bg-cover before:absolute before:inset-0 before:bg-opacity-50 before:bg-primary-800"
    style={{
      backgroundImage: `url("${backgroundImage}")`,
    }}
  >
    <div className="container relative flex flex-col items-center py-24 text-center text-white lg:py-40">
      <h1 className="mb-4 xl:text-5xl drop-shadow">{heading}</h1>
      {subheading && (
        <h2 className="max-w-2xl mb-8 text-lg font-normal font-body drop-shadow">
          {subheading}
        </h2>
      )}

      {ctaLabel && ctaOnClick ? (
        <Button buttonStyle="secondary" label={ctaLabel} onClick={ctaOnClick} />
      ) : (
        <ZipForm buttonStyle="primary" align="left" />
      )}
    </div>
  </section>
);

HeroSection.defaultProps = {
  ctaLabel: null,
  ctaOnClick: null,
  subheading: null,
  backgroundImage: '',
};

HeroSection.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaOnClick: PropTypes.func,
  backgroundImage: PropTypes.string,
};

export default HeroSection;
