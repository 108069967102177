import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';

const ZipForm = ({ align, buttonStyle, buttonText, submitUrl }) => {
  const [zipCode, setZipCode] = useState('');
  const [zipCodeValid, setZipCodeValid] = useState(false);

  useEffect(() => {
    setZipCodeValid(/\d{5}/g.test(zipCode));
  }, [zipCode]);

  const handleSubmit = e => {
    const url = new URL(submitUrl);
    url.searchParams.set('listing_address', zipCode);
    window.location.href = url.href;
    e.preventDefault();
  };

  return (
    <form
      className={classNames('flex flex-col w-full gap-3 md:flex-row md:w-128', {
        'justify-center md:mx-auto md:w-auto': align === 'center',
      })}
      onSubmit={handleSubmit}
    >
      <Input
        className="text-black"
        value={zipCode}
        onChange={value => setZipCode(value)}
        name="Zip Code"
        placeholder="Zip Code"
        maxLength={5}
        required
      />

      <Button
        buttonStyle={buttonStyle}
        className="max-w-none md:max-w-24"
        disabled={!zipCodeValid}
        label={buttonText}
        onClick={handleSubmit}
      />
    </form>
  );
};

ZipForm.defaultProps = {
  buttonStyle: 'tertiary',
  buttonText: 'Get Started',
  align: 'center',
  submitUrl: 'https://go.homebay.com/homebay-clever-flat-fee/',
};

ZipForm.propTypes = {
  buttonStyle: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  buttonText: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  submitUrl: PropTypes.string,
};

export default ZipForm;
