/* eslint-disable camelcase */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout/Layout';
import SEO from '../components/meta/SEO';
import HeroSection from '../components/organisms/HeroSection';
import HomeArticles from '../components/organisms/HomeArticles';
import ZipCtaSection from '../components/organisms/ZipCTASection';

const IndexPage = () => {
  const { content } = useStaticQuery(
    graphql`
      {
        content: cosmicjsHomepageContent {
          metadata {
            featured_image {
              imgix_url
            }
            hero: hero_section {
              heading
              subheading
            }
            popularArticles: popular_articles_section {
              articles {
                url
              }
            }
            zipCta: zip_cta_section {
              heading
              subheading
            }
          }
        }
      }
    `,
  );

  const { featured_image, hero, popularArticles, zipCta } = content.metadata;
  return (
    <Layout>
      <SEO
        pathname="/"
        title="Real Estate Tips, Advice, Updates &amp; More"
        description="Read the latest real estate tips, updates, advice &amp; more at Home Bay. Learn everything you need to know about real estate today!"
      />

      <HeroSection
        heading={hero.heading}
        subheading={hero.subheading}
        backgroundImage={
          featured_image && featured_image.imgix_url
            ? featured_image.imgix_url
            : ''
        }
      />

      <HomeArticles
        links={popularArticles.articles.map(({ url }) => url)}
        lazyloadImages={false}
      />

      <ZipCtaSection heading={zipCta.heading} subheading={zipCta.subheading} />
    </Layout>
  );
};

export default IndexPage;
